var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
      attrs: { id: "page-login" },
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4",
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vx-row no-gutter justify-center items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col hidden lg:block lg:w-1/2" },
                      [_c("logo", { attrs: { public: "" } })],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vx-logo flex lg:hidden justify-items-center justify-center items-center mb-2 pt-4",
                          },
                          [_c("logo", { attrs: { public: "" } })],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "p-4 mb-3" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-card__title mb-4 grid justify-items-center",
                              },
                              [
                                _c("h4", { staticClass: "mb-3" }, [
                                  _vm._v("Login Simulado"),
                                ]),
                                _c("p", { staticClass: "mb-3" }, [
                                  _vm._v(
                                    " Não utilize esta funcionalidade se não for administrador, sujeito a bloqueio de sua conta. "
                                  ),
                                ]),
                              ]
                            ),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|email|min:3",
                                  expression: "'required|email|min:3'",
                                },
                              ],
                              staticClass: "w-full no-icon-border mb-4",
                              attrs: {
                                "data-vv-validate-on": "blur",
                                name: "email",
                                icon: "icon icon-user",
                                "icon-pack": "feather",
                                "label-placeholder": "Email",
                                placeholder: _vm.$t("email"),
                                "data-vv-as": "Email",
                              },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.simulate.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.email,
                                callback: function ($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email",
                              },
                            }),
                            _c("span", { staticClass: "text-danger text-sm" }, [
                              _vm._v(_vm._s(_vm.errors.first("email"))),
                            ]),
                            _c(
                              "vs-button",
                              {
                                staticClass: "float-right mb-4",
                                attrs: { disabled: !_vm.validateForm },
                                on: { click: _vm.simulate },
                              },
                              [_vm._v("Simular")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }